// import React, { useState, useRef } from 'react';

// const AudioRecorder = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [audioURL, setAudioURL] = useState('');
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const chunks = useRef([]);

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then(stream => {
//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (e) => {
//           chunks.current.push(e.data);
//         };
//         recorder.onstop = () => {
//           const blob = new Blob(chunks.current, { type: 'audio/webm' });
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           chunks.current = []; // Clear previous recording data
//         };
        
//         recorder.start();
//         setIsRecording(true);
//         setMediaRecorder(recorder);

//         // Stop recording after 10 seconds
//         setTimeout(() => {
//           if (recorder.state === 'recording') {
//             recorder.stop();
//             setIsRecording(false);
//           }
//         }, 10000); // 10 seconds limit
//       })
//       .catch(err => console.error('Error accessing microphone', err));
//   };

//   const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//       mediaRecorder.stop();
//       setIsRecording(false);
//     }
//   };

//   return (
//     <div>
//       <button onClick={startRecording} disabled={isRecording}>
//         Start Recording
//       </button>
//       <button onClick={stopRecording} disabled={!isRecording}>
//         Stop Recording
//       </button>

//       {audioURL && (
//         <audio controls>
//           <source src={audioURL} type="audio/webm" />
//           Your browser does not support the audio element.
//         </audio>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;


// new code for input support

// import React, { useState, useRef } from 'react';

// const AudioRecorder = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [blobURL, setBlobURL] = useState('');
//   const [inputBlobURL, setInputBlobURL] = useState('');
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const chunks = useRef([]);

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then(stream => {
//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (e) => {
//           chunks.current.push(e.data);
//         };
//         recorder.onstop = () => {
//           const blob = new Blob(chunks.current, { type: 'audio/webm' });
//           const url = URL.createObjectURL(blob);
//           setBlobURL(url);
//           chunks.current = []; // Clear previous recording data
//         };

//         recorder.start();
//         setIsRecording(true);
//         setMediaRecorder(recorder);

//         // Stop recording after 10 seconds
//         setTimeout(() => {
//           if (recorder.state === 'recording') {
//             recorder.stop();
//             setIsRecording(false);
//           }
//         }, 10000); // 10 seconds limit
//       })
//       .catch(err => console.error('Error accessing microphone', err));
//   };

//   const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//       mediaRecorder.stop();
//       setIsRecording(false);
//     }
//   };

//   const handleInputChange = (e) => {
//     setInputBlobURL(e.target.value);
//   };

//   return (
//     <div>
//       <button onClick={startRecording} disabled={isRecording}>
//         Start Recording
//       </button>
//       <button onClick={stopRecording} disabled={!isRecording}>
//         Stop Recording
//       </button>

//       {/* Show the blob URL and allow the user to copy it */}
//       {blobURL && (
//         <div>
//           <p>Blob URL:</p>
//           <input type="text" value={blobURL} readOnly />
//         </div>
//       )}

//       {/* Input box where the user can paste the blob URL */}
//       <div>
//         <p>Paste Blob URL to play:</p>
//         <input type="text" value={inputBlobURL} onChange={handleInputChange} />
//       </div>

//       {/* Play the audio from the pasted Blob URL */}
//       {inputBlobURL && (
//         <audio controls>
//           <source src={inputBlobURL} type="audio/webm" />
//           Your browser does not support the audio element.
//         </audio>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;



//Download
// import React, { useState, useRef } from 'react';

// const AudioRecorder = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [blobURL, setBlobURL] = useState('');
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const chunks = useRef([]);

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then(stream => {
//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (e) => {
//           chunks.current.push(e.data);
//         };
//         recorder.onstop = () => {
//           const blob = new Blob(chunks.current, { type: 'audio/webm' });
//           const url = URL.createObjectURL(blob);
//           setBlobURL(url);
//           chunks.current = []; // Clear previous recording data
//         };

//         recorder.start();
//         setIsRecording(true);
//         setMediaRecorder(recorder);

//         // Stop recording after 10 seconds
//         setTimeout(() => {
//           if (recorder.state === 'recording') {
//             recorder.stop();
//             setIsRecording(false);
//           }
//         }, 10000); // 10 seconds limit
//       })
//       .catch(err => console.error('Error accessing microphone', err));
//   };

//   const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//       mediaRecorder.stop();
//       setIsRecording(false);
//     }
//   };

//   return (
//     <div>
//       <button onClick={startRecording} disabled={isRecording}>
//         Start Recording
//       </button>
//       <button onClick={stopRecording} disabled={!isRecording}>
//         Stop Recording
//       </button>

//       {/* Show the blob URL */}
//       {blobURL && (
//         <div>
//           <p>Blob URL (local):</p>
//           <input type="text" value={blobURL} readOnly />
          
//           {/* Download button to save the audio file locally */}
//           <a href={blobURL} download="recorded-audio.webm">
//             <button>Download Recording</button>
//           </a>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;


// base64

// import React, { useState, useRef } from 'react';

// const AudioRecorder = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [base64Audio, setBase64Audio] = useState('');
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const chunks = useRef([]);

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then(stream => {
//         const recorder = new MediaRecorder(stream);
//         recorder.ondataavailable = (e) => {
//           chunks.current.push(e.data);
//         };
//         recorder.onstop = () => {
//           const blob = new Blob(chunks.current, { type: 'audio/webm' });
//           convertBlobToBase64(blob); // Convert the Blob to Base64
//           chunks.current = []; // Clear previous recording data
//         };

//         recorder.start();
//         setIsRecording(true);
//         setMediaRecorder(recorder);

//         // Stop recording after 10 seconds
//         setTimeout(() => {
//           if (recorder.state === 'recording') {
//             recorder.stop();
//             setIsRecording(false);
//           }
//         }, 10000); // 10 seconds limit
//       })
//       .catch(err => console.error('Error accessing microphone', err));
//   };

//   const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//       mediaRecorder.stop();
//       setIsRecording(false);
//     }
//   };

//   const convertBlobToBase64 = (blob) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onloadend = () => {
//       const base64data = reader.result;
//       setBase64Audio(base64data); // Store the Base64 string in state
//     };
//   };

//   const handleBase64InputChange = (e) => {
//     setBase64Audio(e.target.value);
//   };

//   return (
//     <div>
//       <button onClick={startRecording} disabled={isRecording}>
//         Start Recording
//       </button>
//       <button onClick={stopRecording} disabled={!isRecording}>
//         Stop Recording
//       </button>

//       {/* Input box to display the Base64 audio string */}
//       {base64Audio && (
//         <div>
//           <p>Base64 Audio String:</p>
//           <textarea
//             value={base64Audio}
//             onChange={handleBase64InputChange}
//             rows="5"
//             cols="50"
//           />
//         </div>
//       )}

//       {/* Play the audio from the Base64 string */}
//       {base64Audio && (
//         <audio controls>
//           <source src={base64Audio} type="audio/webm" />
//           Your browser does not support the audio element.
//         </audio>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;





// new code and backend code
import React, { useState, useRef } from "react";
import axios from "axios";

const AudioRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [uploadedURL, setUploadedURL] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  // Start Recording
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      console.log("audioBlob-->",audioBlob)
      setAudioBlob(audioBlob);
      uploadAudio(audioBlob); // Upload after recording is stopped
    };

    mediaRecorderRef.current.start();
    setRecording(true);
  };

  // Stop Recording
  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    audioChunks.current = []; // Clear the chunks after recording
  };

  // Upload Audio
  const uploadAudio = async (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  return (
    <div className="audio-recorder">
      <h2>Audio Recorder</h2>
      {/* Button to Start/Stop Recording */}
      <button onClick={recording ? stopRecording : startRecording}>
        {recording ? "Stop Recording" : "Start Recording"}
      </button>

      {/* Audio Player: Displays only when uploadedURL is available */}
      {uploadedURL && (
        <div>
          <h3>Uploaded Audio</h3>
          <audio controls src={uploadedURL}></audio>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
